import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { FaUserCircle, FaCog, FaHeadset, FaLock, FaPowerOff, FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/actions';
import { clearState } from '../redux/localStorage';
import { connect } from 'react-redux';

const DropdownMenu = (state) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate(); // Get the navigate function
    const dispatch = useDispatch();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        dispatch(logout());
        clearState();
        navigate(`/login`); // Redirect to login page or any other page  
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <button onClick={toggleMenu} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <FaUserCircle size={24} />
            </button>
            {isOpen && (
                <div style={{
                    position: 'absolute',
                    right: 0,
                    background: 'white',
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px',
                    zIndex: 1,
                    padding: '8px 0',
                }}>
                    <span style={{ display: 'flex', alignItems: 'center', padding: '8px 16px', textDecoration: 'none', color: 'black' }}>
                        <FaUser style={{ marginRight: '8px' }} />{state.state.user.email}
                    </span>
                    <a href="#" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', padding: '8px 16px', textDecoration: 'none', color: 'black' }}>
                        <FaPowerOff style={{ marginRight: '8px' }} /> Logout
                    </a>
                </div>
            )}
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
      state: state.myReducer
    };
  };
  
  export default connect(mapStateToProps)(DropdownMenu);
  
