import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Step1 from '../components/Step1'
import { useDispatch, connect } from 'react-redux';
import BuilderProgress from '../components/BuilderProgress';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';

const BuilderPage = () => {
  const { id } = useParams();
  return (
    < React.Fragment >
    <div className="container">
      <div class="col-md-3 d-block d-md-none">
        <MobileMenu />
      </div>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="main-content">
        <Row>
          <AdminHeader />
        </Row>
        <Row>
          <Col className="adminBackground">
            <BuilderProgress id={id} stage='1' />
            <Step1 id={id} />
          </Col>
        </Row>
      </div>
    </div>
    </React.Fragment >
  );
};



const mapStateToProps = (state) => {
  return {
    jobs: state.myReducer.jobs
  };
};

export default connect(mapStateToProps)(BuilderPage);