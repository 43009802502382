import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import { useDispatch, connect } from 'react-redux';
import { refreshJobs, updateUserCredits } from '../redux/actions';
import BuilderProgress from '../components/BuilderProgress';
import MobileMenu from '../components/MobileMenu';
import { fetchData } from '../helpers/api';
import { GiCheckMark } from "react-icons/gi";
import Select from 'react-select';
import countryList from 'react-select-country-list';

const Step4Page = (allstate) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [warning, setWarning] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryOptions, setCountryOptions] = useState(countryList().getData());
    const { id } = useParams();
    const [selectedValue, setSelectedValue] = useState(10); // Preselect 10 records  

    const returnToMenu = () => {
        setWaiting(false);
        navigate(`/dashboard`);
    };

    const Spinner = () => (
        <React.Fragment>
            <div className="spinner-overlay">
                <div className="tick-container">
                    <div>
                        <GiCheckMark className="tick-icon" />
                    </div>
                    <span className="tick-text">Submitted!</span>
                    <div className="tick-text-sml">We'll email you once your list is ready</div>
                    <br /><br />
                    <Button onClick={returnToMenu}>Return to menu</Button>
                </div>
            </div>
        </React.Fragment>
    );

    const handleSelectChange = (event, locationCount) => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);

        const val = parseInt(selectedValue, 10);
        if (val / 20 > locationCount) {
            setWarning('Warning: we recommend selecting at least ' + Math.ceil(selectedValue / 20) + ' locations before proceeding to ensure a high quality list is sourced.');
        } else {
            setWarning('');
        }
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
    };

    const handleSubmit = async () => {
        setWaiting(true);
        if (selectedValue) {
            const updatedJobs = allstate.allstate.jobs.map((job) => {
                if (job._id === id) {
                    return {
                        ...job,
                        progress: {
                            ...job.progress,
                            status: "Submitted",
                            numRows: selectedValue,
                        },
                        textGen: {
                            ...job.textGen,
                            bio: allstate.allstate.user.bio,
                            summarisedBio: allstate.allstate.user.bio,
                        },
                    };
                }
                return job;
            });

            let deductCreditValue = parseInt(selectedValue, 10);
            let newCredits = allstate.allstate.user.credits - deductCreditValue;
            await dispatch(updateUserCredits(newCredits));
            let url = process.env.REACT_APP_WFE_URL + "/database/updateuser";
            fetchData(url, { "_id": allstate.allstate.user.id, "credits": newCredits }, { "jwtToken": allstate.allstate.user.jwt });
            dispatch(refreshJobs(updatedJobs));
            let url2 = process.env.REACT_APP_WFE_URL + "/database/update";
            updatedJobs.map(async (job) => {
                if (job._id === id) {
                    job.SearchQuery.searchregion = selectedCountry.value.toLowerCase();
                    fetchData(url2, job, { "jwtToken": allstate.allstate.user.jwt });
                }
            });
        }
    };

    return (
        <React.Fragment>
            {waiting && <Spinner />}
            <div className="container">
                <div className="col-md-3 d-block d-md-none">
                    <MobileMenu />
                </div>
                <div className="sidebar">
                    <Sidebar />
                </div>
                <div className="main-content">
                    <Row>
                        <AdminHeader />
                    </Row>
                    <Row>
                        <Col className="adminBackground">
                            <BuilderProgress id={id} stage='4' />
                            <h2>Submit Job</h2>
                            Are you ready to go? Our machines are ready to run your internet searches, collect a whole lot of great information about the organisations you wish to target, and write up your prospect emails and call scripts!
                            <br /><br />
                            Just let us know how large you'd like your list to be, and we'll take care of everything from here.
                            <br /><br />
                            {allstate.allstate.jobs.map((job, index) => (
                                <React.Fragment key={index}>
                                    {job._id === id ? (
                                        <React.Fragment>
                                            <div className="component-card">
                                                <h3>Your Search</h3>
                                                <span>
                                                    <span className={"custom-form-label"}>Search terms:</span>
                                                    <br />
                                                    {job.SearchQuery.selectedkeywords.map((k, index) => (
                                                        <React.Fragment key={index}>
                                                            {k}{index < job.SearchQuery.selectedkeywords.length - 1 && ', '}
                                                        </React.Fragment>
                                                    ))}
                                                    <br /><br />
                                                    <span className={"custom-form-label"}>Locations:</span>
                                                    <br />
                                                    {job.SearchQuery.selectedlocations.map((loc, index) => (
                                                        <React.Fragment key={index}>
                                                            {loc}{index < job.SearchQuery.selectedlocations.length - 1 && ', '}
                                                        </React.Fragment>
                                                    ))}
                                                    <br /><br />
                                                    <span className={"custom-form-label"}>Search region:</span>
                                                    <Select
                                                        options={countryOptions}
                                                        value={selectedCountry}
                                                        onChange={handleCountryChange}
                                                        placeholder="Select a country"
                                                        isClearable
                                                    />
                                                    <p>To get the best localised results, select the region where you'd like us to run web searches for. eg: select "United Kingdom" if you're building a list of businesses in Manchester.</p>
                                                    <span className={"custom-form-label"}>List size:</span>
                                                    <Form.Select aria-label="Default select example" value={selectedValue} onChange={(event) => handleSelectChange(event, job.SearchQuery.selectedlocations.length)}>
                                                        <option value="3">3 records</option>
                                                        <option value="10">10 records</option>
                                                        <option value="20">20 records</option>
                                                        <option value="50">50 records</option>
                                                        <option value="100">100 records</option>
                                                        <option value="200">200 records</option>
                                                    </Form.Select>
                                                    {warning && <p style={{ color: '#D46728' }}>{warning}</p>}
                                                    <div>List build time: allow 1-2 mins per record. We'll email you once your list is ready. 🚀</div>
                                                    <p></p>
                                                    <button className={"selected-button"} onClick={handleSubmit}>Submit</button>
                                                </span>
                                            </div>
                                        </React.Fragment>
                                    ) : null}
                                </React.Fragment>
                            ))}
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        allstate: state.myReducer
    };
};

export default connect(mapStateToProps)(Step4Page);  