// myReducer.js  

const initialState = {
  user: {},
  jobs: [],
  singlesite: [],
  listupload: [],
  subscriptions: [],
  cadences: []
};

const myReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_JWT':
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload.email,
          jwt: action.payload.jwt,
          id: action.payload.id,
          bio: action.payload.bio,
          language: action.payload.language,
          isAuthenticated: true,
          credits: action.payload.credits,
          renewalday: action.payload.renewalday,
          plancode: action.payload.plancode,
          countryCode: action.payload.countryCode,
          stripeCustomerId: action.payload.stripeCustomerId
        },
      };
    case 'UPDATE_USER_BIO':
      return {
        ...state,
        user: {
          ...state.user,
          bio: action.payload
        },
      };
    case 'REFRESH_USER':
      console.log("REFRESH_USER reducer called with payload" + JSON.stringify(action.payload.subscriptions))
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload.email,
          bio: action.payload.bio,
          credits: action.payload.credits,
        }
      };
    case 'UPDATE_USER_CREDITS':
      return {
        ...state,
        user: {
          ...state.user,
          credits: action.payload
        },
      };
    case 'UPDATE_USER_LANGUAGE':
      return {
        ...state,
        user: {
          ...state.user,
          language: action.payload
        },
      };
    case 'REFRESH_JOBS':
      return {
        ...state,
        jobs: action.payload
      };
    case 'REFRESH_SUBSCRIPTIONS':
      // console.log("REFRESH_SUBSCRIPTIONS RUNNING WITH " + JSON.stringify(action.payload))
      return {
        ...state,
        subscriptions: action.payload
      };
    case 'REFRESH_SINGLE_SITE':
      return {
        ...state,
        singlesite: action.payload
      };
    case 'REFRESH_CADENCES':
      return {
        ...state,
        cadences: action.payload
      };
    case 'REFRESH_LIST_UPLOAD':
      // console.log("REFRESH_LIST_UPLOAD running with " + JSON.stringify(action.payload))
      return {
        ...state,
        listupload: action.payload
      };
    case 'LOGOUT':
      return initialState; // Reset state to initial state  
    default:
      return state;
  }
};

export default myReducer;  
