import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { FaSave, FaTrash, FaArrowUp, FaArrowDown, FaPlus, FaPhone, FaLinkedin, FaCheckCircle } from 'react-icons/fa';
import { GrTest } from "react-icons/gr";
import { TbPlaneDeparture } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdOutlineMailOutline } from "react-icons/md";
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { useDispatch, connect } from 'react-redux';
import { fetchData } from '../helpers/api';

const CadenceEditPage = (allstate) => {
    const { id } = useParams(); // Get the id from the URL parameters  
    const [loadingIndex, setLoadingIndex] = useState(false);
    const [data, setData] = useState({
        _id: id,
        userId: allstate.allstate.user.id,
        sampleProspectBio: "",
        lastModified: Date.now(),
        cadence: []
    });
    const [sampleProspectBio, setsampleProspectBio] = useState(data.sampleProspectBio); // New state for sampleProspectBio  
    const [sampleSellerBio, setSampleSellerBio] = useState(""); // New state for sample seller bio  
    const [showExample, setShowExample] = useState(false);

    const toggleExample = () => {
        setShowExample(!showExample);
    };

    const getExampleText = (type) => {
        switch (type) {
            case 'email':
                return `Write "Subject:" then enter a concise and intriguing subject that gains their interest.<br>  
                        Write a customer-ready email containing the following items in order:<br>  
                        1. explain what you know of the business from your research, act as if you have known about them for a long time<br>  
                        2. mention a common challenge you see companies like this face that you can help them with<br>  
                        3. state your purpose and value proposition in a positioning statement.<br>  
                        4. offer a soft close and next steps to invite further interaction. Suggest a timeframe reasonable for the industry this prospect is in.<br>  
                        Each item MUST be a new paragraph.<br>  
                        Keep the total email under 150 words.`;
            case 'phone':
                return `Structure the call script in the follow manner:<br>  
                    INTRO - greet the prospect, introduce yourself and your company.<br>  
                    TRANSITION - smoothly segue into the purpose of the call.<br>  
                    VALUE PROPOSITION - states the benefits and solutions your product or service offers for the prospect's needs.<br>  
                    QUALIFYING QUESTION - show your expertise with open-ended question to understand your prospect's challenges and gather relevant information.<br>  
                    NEXT STEPS - proposes a clear next step based on the prospect's interest, such as scheduling a demo or sending more information.<br>  
                    CLOSE - thanks the prospect for their time and leaves the door open for future communication.<br>  
                    Always write the script with 2 people: "Prospect" (the person calling) and "You" the caller.`;
            case 'linkedin':
                return `Write a succinct and compelling DM I can send on LinkedIn to a prospect based on how I can help their company given the work we both do. Tell them they can expect to hear from me soon via phone.`;
            default:
                return ''; // Return an empty string if type is not recognized  
        }
    };

    function generateHexCode() {
        const randomColor = Math.floor(Math.random() * 65536).toString(16); // 65536 is 0x10000 in decimal  
        return `#${randomColor.padStart(4, '0')}`;
    }

    const addNewItem = (position) => {
        var stepId = id + generateHexCode();
        const lastItemDaysDelay = data.cadence.length > 0 ? data.cadence[data.cadence.length - 1].daysDelay : 0; // Get the daysDelay of the last item or default to 0  

        const newItem = {
            stepid: stepId,
            name: 'Email',
            type: 'email',
            position: position,
            prompt: '',
            requireUserContent: false,
            textBoxTitle: '',
            textTip: '',
            testRequireUserContent: '',
            isExpanded: false,
            testResponse: null,
            daysDelay: lastItemDaysDelay, // Use the last item's daysDelay  
            isPromptRequired: true // Default checkbox value  
        };
        const updatedCadence = [...data.cadence.slice(0, position), newItem, ...data.cadence.slice(position)];
        setData({ ...data, cadence: updatedCadence, lastModified: Date.now() });
    };

    const editItem = (index) => {
        const updatedCadence = data.cadence.map((item, idx) => ({
            ...item,
            isExpanded: idx === index ? !item.isExpanded : false // Toggle current index and collapse others  
        }));
        setData({ ...data, cadence: updatedCadence });
    };

    const handleInputChange = (index, field, value) => {
        const updatedCadence = [...data.cadence];
        updatedCadence[index][field] = value;
        setData({ ...data, cadence: updatedCadence, lastModified: Date.now() });
    };

    const changeType = (index, newType) => {
        const updatedCadence = [...data.cadence];
        // Map newType to the corresponding name  
        const typeToNameMap = {
            phone: 'Call Script',
            linkedin: 'LinkedIn Message',
            email: 'Email'
        };
        updatedCadence[index].type = newType;
        updatedCadence[index].name = typeToNameMap[newType] || updatedCadence[index].name; // Fallback to current name if newType is not found  
        setData({ ...data, cadence: updatedCadence, lastModified: Date.now() });
    };

    const moveItem = (index, direction) => {
        const updatedCadence = [...data.cadence];
        const [movedItem] = updatedCadence.splice(index, 1);
        const newPosition = index + direction;
        updatedCadence.splice(newPosition, 0, movedItem);

        // Update the daysDelay for each item based on new positions  
        updatedCadence.forEach((item, idx) => {
            item.daysDelay = idx === 0 ? 0 : idx; // Set Day 0 for the first item, or its index for others  
        });
        setData({ ...data, cadence: updatedCadence, lastModified: Date.now() });
    };

    const deleteItem = (index) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const updatedCadence = data.cadence.filter((_, i) => i !== index);
            setData({ ...data, cadence: updatedCadence, lastModified: Date.now() });
        }
    };

    const onSave = async () => {
        const finalData = {
            ...data,
            sampleSellerBio // Include the new sample seller bio  
        };
        console.log(JSON.stringify(finalData, null, 2));
        let url = process.env.REACT_APP_WFE_URL + "/database/updatecadence"
        fetchData(url, finalData, { "jwtToken": allstate.allstate.user.jwt });
    };

    const toggleTestResponseExpansion = (index) => {
        const updatedCadence = [...data.cadence];
        updatedCadence[index].isTestResponseExpanded = !updatedCadence[index].isTestResponseExpanded; // Toggle expanded state  
        setData({ ...data, cadence: updatedCadence });
    };

    const onTest = async (item) => {
        setLoadingIndex(true); // Set loading index to disable the button  
        try {
            // Simulating a server call with a timeout  
            await new Promise(resolve => setTimeout(resolve, 2000)); // Simulate 2 seconds wait  
            // Simulate some test response logic  
            let payload = {
                prompt: item.prompt,
                sampleSellerBio: sampleSellerBio,
                sampleProspectBio: sampleProspectBio,
                textBoxTitle: item.textBoxTitle,
                testRequireUserContent: item.testRequireUserContent
            };
            // Create a copy of the current cadence  
            const updatedCadence = [...data.cadence];
            // Update the testResponse for the item at the correct index  
            updatedCadence[item.index].testResponse = JSON.stringify(payload);
            // Update the state with the new cadence  
            setData({ ...data, cadence: updatedCadence, lastModified: Date.now() });
            console.log(JSON.stringify(updatedCadence[item.index], null, 2));
        } finally {
            setLoadingIndex(false); // Reset loading index after completion  
        }
    };

    const handleTestRequireUserContentChange = (index, value) => {
        const updatedCadence = [...data.cadence];
        updatedCadence[index].testRequireUserContent = value;
        setData({ ...data, cadence: updatedCadence, lastModified: Date.now() });
    };

    const handlesampleProspectBioChange = (e) => {
        const value = e.target.value;
        if (value.length <= 700) {
            setsampleProspectBio(value);
            setData({ ...data, sampleProspectBio: value });
        }
    };

    const handleSampleSellerBioChange = (e) => {
        const value = e.target.value;
        if (value.length <= 800) {
            setSampleSellerBio(value);
            setData({ ...data, sampleSellerBio: value });
        }
    };

    return (
        <React.Fragment>
            <div className="container">
                <div className="sidebar">
                    <Sidebar />
                </div>
                <div className="main-content">
                    <Row>
                        <AdminHeader />
                    </Row>
                    <div className="col-md-3 d-block d-md-none">
                        <MobileMenu />
                    </div>
                    <Row>
                        <Col className="adminBackground">
                            <h1>Edit Cadence</h1>
                            <h5>(Not visible to users)</h5>
                            <br></br>
                            <div>
                                {data.cadence.map((item, index) => (
                                    <Card key={index} className="card-dashboard">
                                        <Card.Body>
                                            <Row>
                                                <React.Fragment>
                                                    <Col
                                                        xs={item.isExpanded ? 8 : 12}
                                                        onClick={() => editItem(index)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            fontSize: '1.4em',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <span style={{ marginRight: '8px' }}>
                                                            {item.type === 'linkedin' ? (
                                                                <FaLinkedin color="#0D4D3D" size={26} />
                                                            ) : item.type === 'email' ? (
                                                                <MdOutlineMailOutline color="#0D4D3D" size={26} />
                                                            ) : (
                                                                <FaPhone color="#0D4D3D" size={26} />
                                                            )}
                                                        </span>
                                                        <span style={{ flex: 1 }}>
                                                            {item.name}
                                                        </span>
                                                        {/* Show Day value only when item is not expanded */}
                                                        {!item.isExpanded && (
                                                            <span style={{ marginLeft: 'auto', textTransform: 'uppercase', fontSize: '0.7em' }}>
                                                                Day {item.daysDelay}
                                                            </span>
                                                        )}
                                                    </Col>
                                                    {item.isExpanded && (
                                                        <>
                                                            <Col xs={4} className="text-right">
                                                                {item.type !== 'linkedin' && (
                                                                    <span style={{ margin: '0 8px' }} onClick={() => changeType(index, 'linkedin')}>
                                                                        <FaLinkedin color="#0D4D3D" size={24} />
                                                                    </span>
                                                                )}
                                                                {item.type !== 'email' && (
                                                                    <span style={{ margin: '0 8px' }} onClick={() => changeType(index, 'email')}>
                                                                        <MdOutlineMailOutline color="#0D4D3D" size={24} />
                                                                    </span>
                                                                )}
                                                                {item.type !== 'phone' && (
                                                                    <span style={{ margin: '0 8px' }} onClick={() => changeType(index, 'phone')}>
                                                                        <FaPhone color="#0D4D3D" size={24} />
                                                                    </span>
                                                                )}
                                                                <span style={{ margin: '0 8px' }}>|</span>
                                                                <span style={{ margin: '0 8px' }} onClick={() => moveItem(index, -1)} disabled={index === 0}>
                                                                    <FaArrowUp color="#0D4D3D" size={24} />
                                                                </span>
                                                                <span style={{ margin: '0 8px' }} onClick={() => moveItem(index, 1)} disabled={index === data.cadence.length - 1}>
                                                                    <FaArrowDown color="#0D4D3D" size={24} />
                                                                </span>
                                                                <span style={{ margin: '0 8px' }} onClick={() => deleteItem(index)}>
                                                                    <FaTrash color="#D46728" size={24} />
                                                                </span>
                                                            </Col>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            </Row>
                                            {item.isExpanded && (
                                                <div>
                                                    <div className="divider"></div>
                                                    <Form.Group>
                                                        <Form.Label>Cadence Day</Form.Label>
                                                        <div className="form-tip">The day of the cadence you recommend this step is actioned.</div>
                                                        <Form.Control
                                                            as="select"
                                                            value={item.daysDelay !== null ? item.daysDelay : ""}
                                                            onChange={(e) => handleInputChange(index, 'daysDelay', parseInt(e.target.value))}
                                                        >
                                                            {[...Array(31).keys()].filter(day => day >= item.daysDelay).map((day) => (
                                                                <option key={day} value={day}>Day {day}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <br></br>
                                                    <Form.Group>
                                                        <Form.Check
                                                            id={`promptRequired-${index}`} // Unique ID for the checkbox  
                                                            type="checkbox"
                                                            label="Is Prompt Required?"
                                                            checked={item.isPromptRequired}
                                                            onChange={(e) => handleInputChange(index, 'isPromptRequired', e.target.checked)}
                                                        />
                                                        <div className="form-tip">Check if a prompt is required for this step.</div>
                                                    </Form.Group>
                                                    {item.isPromptRequired && ( // Conditional rendering  
                                                        <>
                                                            <Form.Group>
                                                                <Form.Label>Prompt</Form.Label>
                                                                <div className="form-tip">Write complete instructions for Jummbo here to generate this {item.name.toLowerCase()}.</div>
                                                                <div className="form-tip">Seller and prospect bio's will be added automatically.</div>
                                                                <div>
                                                                    <span
                                                                        className='hyperlink'
                                                                        onClick={toggleExample}
                                                                    >
                                                                        {showExample ? 'Hide Example' : 'View Example'}
                                                                        <br></br><br></br>
                                                                    </span>
                                                                </div>
                                                                {showExample && (
                                                                    <div className="example-text">
                                                                        <p>
                                                                            <div className="example-text" dangerouslySetInnerHTML={{ __html: getExampleText(item.type) }} />
                                                                        </p>
                                                                    </div>
                                                                )}
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={9}
                                                                    value={item.prompt}
                                                                    onChange={(e) => handleInputChange(index, 'prompt', e.target.value)}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    )}
                                                    <br></br>
                                                    <Form.Group>
                                                        <Form.Check
                                                            id={`requireUserContent-${index}`} // Unique ID for the checkbox  
                                                            type="checkbox"
                                                            label="Require User Content?"
                                                            checked={item.requireUserContent}
                                                            onChange={(e) => handleInputChange(index, 'requireUserContent', e.target.checked)}
                                                        />
                                                        <div className="form-tip">Tick if the user needs to provide additional information to the prompt, for example details of a case study which is to be referred to in this step. They can provide this information on their "Settings" page.</div>
                                                    </Form.Group>
                                                    {item.requireUserContent && ( // Conditional rendering  
                                                        <>
                                                            <br></br>
                                                            <Form.Group>
                                                                <Form.Label>Text Box Title</Form.Label>
                                                                <div className="form-tip">The title which your user will see in their settings page, for example "Case Study".</div>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={item.textBoxTitle}
                                                                    onChange={(e) => handleInputChange(index, 'textBoxTitle', e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label>Text Tip</Form.Label>
                                                                <div className="form-tip">Provide a short text tip to help the user complete this. For example you could write, "provide a paragraph or two of text describing the case study which Jummbo will refer to in this step".</div>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={item.textTip}
                                                                    onChange={(e) => handleInputChange(index, 'textTip', e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            {item.requireUserContent && (
                                                                <>
                                                                    <br></br>
                                                                    <Form.Group>
                                                                        <Form.Label>Test Require User Content</Form.Label>
                                                                        <div className="form-tip">Used for your testing only - include sample content, such as an example case study, to help validate the quality of this {item.name.toLowerCase()}.</div>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={9}
                                                                            value={item.testRequireUserContent} // Bind to the new state  
                                                                            onChange={(e) => handleTestRequireUserContentChange(index, e.target.value)} // Call the new handler  
                                                                        />
                                                                    </Form.Group>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className="divider"></div>
                                                    {item.testResponse && (
                                                        <>
                                                            <h5>Test Response</h5>
                                                            <div className="form-tip">
                                                                {item.isTestResponseExpanded
                                                                    ? item.testResponse
                                                                    : item.testResponse.length > 300
                                                                        ? item.testResponse.substring(0, 300) + '...'
                                                                        : item.testResponse
                                                                }
                                                            </div>
                                                            {item.testResponse.length > 300 && (
                                                                <span
                                                                    onClick={() => toggleTestResponseExpansion(index)}
                                                                    className='hyperlink'
                                                                >
                                                                    {item.isTestResponseExpanded ? ' Contract' : ' Expand'}
                                                                </span>
                                                            )}
                                                            <br /><br />
                                                        </>
                                                    )}
                                                    <button
                                                        className={loadingIndex ? "unselected-button" : "selected-button"} // Change class based on loading  
                                                        type="submit"
                                                        style={{ margin: '0 8px' }}
                                                        disabled={loadingIndex === item.index} // Disable button while loading  
                                                        onClick={() => onTest({ ...item, index })}
                                                    >
                                                        <TbPlaneDeparture /> {loadingIndex ? "Testing" : "Test"} {item.name}
                                                    </button>
                                                </div>
                                            )}
                                        </Card.Body>
                                    </Card>
                                ))}
                                <div
                                    onClick={() => addNewItem(data.cadence.length)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        marginTop: '20px' // Adjust as needed  
                                    }}
                                >
                                    <IoMdAddCircleOutline color="#0D4D3D" size={32} style={{ marginRight: '8px' }} />
                                    <span>Add Step</span>
                                </div>
                                <br></br><br></br><br></br>
                                <h4><GrTest /> Test Cadence: Settings</h4>
                                (Not visible to users)
                                <Card className="card-dashboard">
                                    <Card.Body>
                                        <Row>
                                            <React.Fragment>
                                                <Card.Title>Sample Seller Bio</Card.Title>
                                                <Form.Group>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={9}
                                                        maxLength={800} // Enforce character limit in the input  
                                                        value={sampleSellerBio}
                                                        onChange={handleSampleSellerBioChange}
                                                        placeholder="Enter your sample seller bio (max 800 characters)"
                                                    />
                                                    <div className="form-tip">{sampleSellerBio.length}/800 characters</div> {/* Character counter */}
                                                </Form.Group>
                                            </React.Fragment>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className="card-dashboard" style={{ marginTop: '20px' }}>
                                    <Card.Body>
                                        <Card.Title>Sample Prospect Bio</Card.Title>
                                        <div className="form-tip">Write in a bio of a target business that will be used in cadence testing above. End users do not see this, it's for your testing.</div>
                                        <Form.Group>
                                            <Form.Control
                                                as="textarea"
                                                rows={9}
                                                value={sampleProspectBio}
                                                onChange={handlesampleProspectBioChange}
                                                maxLength={700} // Enforce character limit in the input  
                                                placeholder="Enter your test bio (max 700 characters)"
                                            />
                                            <div className="form-tip">{sampleProspectBio.length}/700 characters</div> {/* Character counter */}
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <br></br><br></br>
                                <button
                                    className={"selected-button"}
                                    type="submit"
                                    style={{ margin: '0 8px' }}
                                    onClick={() => onSave()}>
                                    <FaSave /> Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        allstate: state.myReducer
    };
};

export default connect(mapStateToProps)(CadenceEditPage);  