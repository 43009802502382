import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import { useDispatch, connect } from 'react-redux';
import { fetchData } from '../helpers/api'; // Import the fetchData function
import { refreshJobs } from '../redux/actions'; // Import your action creator
import { ImCross } from "react-icons/im";
import { FaAnglesRight } from "react-icons/fa6";
import { ImSpinner9 } from "react-icons/im";
import { fetchText } from '../helpers/fetchText';

const Step1 = ({ id, allstate }) => {
  const [inputValue, setInputValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [readyForStep2, setReadyForStep2] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Get the navigate function
  const [waiting, setWaiting] = useState(false);

  const handleInputChange = (event) => {
    // console.log("Setting input value")
    setInputValue(event.target.value);
  };

  const Spinner = () => (
    //used to block the screen when async jobs are running
    <div className="spinner-overlay">
      <ImSpinner9 className="spinner-icon" />
    </div>
  );


  var selectedKeywordHurdle = 1
  var selectedLocationHurdle = 2

  const checkIfReadyForStep2 = (skeywords, slocations) => {
    let selectedKeywordCount = skeywords.length
    let selectedLocationCount = slocations.length
    // console.log("selectedKeywordCount is: " + selectedKeywordCount)
    // console.log("selectedLocationCount is: " + selectedLocationCount)
    // Check if selectedKeywordCount is more than 3 and selectedLocationCount is at least 1

    if (selectedKeywordCount >= selectedKeywordHurdle && selectedLocationCount >= selectedLocationHurdle) {
      // console.log("selectedKeywordCount minimum achieved with: " + selectedKeywordCount);
      // console.log("selectedLocationCount minimum achieved with: " + selectedLocationCount);
      setReadyForStep2(true)
    } else {
      setReadyForStep2(false)
    }
  };

  const handleButtonClick = async (event) => {
    // console.log("Calling API");  
    setIsSearching(true);
    try {
      // Initial API call  
      let url1 = process.env.REACT_APP_WFE_URL + "/definesearch/initialQuery"
      const resp = await fetchData(url1, { phrase: inputValue }, { jwtToken: allstate.user.jwt });

      // Use Promise.all to ensure all fetch requests in the map are completed  
      const updatedJobs = await Promise.all(allstate.jobs.map(async (job) => {
        if (job._id === id) {
          // Update the job  
          const updatedJob = {
            ...job,
            SearchQuery: {
              topic: job.SearchQuery.topic,
              keywords: resp.keywords,
              locations: resp.locations,
              searchregion: job.SearchQuery.searchregion,
              selectedkeywords: job.SearchQuery.selectedkeywords,
              selectedlocations: job.SearchQuery.selectedlocations
            },
          };

          // Second API call to update SearchQuery.topic  
          let url2 = process.env.REACT_APP_WFE_URL + "/definesearch/setTopic"
          const topicUpdateResp = await fetchData(url2, updatedJob.SearchQuery, { jwtToken: allstate.user.jwt });

          // // Update the topic with the result from the second API call  
          updatedJob.SearchQuery.topic = topicUpdateResp.description;

          return updatedJob;
        }
        // Return the job as-is if it doesn't match the ID to send to the reducer  
        return job;
      }));

      // console.log("updatedJobs is now: " + JSON.stringify(updatedJobs));  
      dispatch(refreshJobs(updatedJobs));
      setIsSearching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error (e.g., show an error message to the user)  
      setIsSearching(false);
    }
  };


  const handleNewSelectedKeyword = async (thisKeyword, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisJobId, jobsState) => {
    //add selected keyword into "selected keywords" array
    // console.log("thisJobId is " + thisJobId)
    // console.log("thisKeyword is " + thisKeyword)
    // console.log("unselectedKeywords is " + unselectedKeywords)
    // console.log("selectedKeywords is " + selectedKeywords)
    // console.log("unselectedLocations is " + unselectedLocations)
    // console.log("selectedLocations is " + selectedLocations)
    // console.log("thisJobId is " + thisJobId)
    // console.log("jobsState is " + jobsState)

    // Create new arrays for selected and unselected keywords
    const updatedUnselectedKeywords = [...unselectedKeywords];
    // console.log("updatedUnselectedKeywordsis " + updatedUnselectedKeywords)
    const updatedSelectedKeywords = [...selectedKeywords];

    //  now take selected keyword out of the original array
    const indexToRemove = updatedUnselectedKeywords.indexOf(thisKeyword); // Find the index of the value to remove
    if (indexToRemove !== -1) {
      updatedUnselectedKeywords.splice(indexToRemove, 1); // Remove the item at the specified index
    }
    // console.log("updatedUnselectedKeywordsis " + updatedUnselectedKeywords)
    // now put the selected keyword into the selected keywords array
    updatedSelectedKeywords.push(thisKeyword)

    // Find the job with the specified ID
    const uj = jobsState.map((job) => {
      if (job._id === thisJobId) {
        // Create a new object with updated properties
        return {
          ...job,
          SearchQuery: {
            topic: job.SearchQuery.topic,
            keywords: updatedUnselectedKeywords,
            locations: unselectedLocations,
            searchregion: job.SearchQuery.searchregion,
            selectedkeywords: updatedSelectedKeywords,
            selectedlocations: selectedLocations,
          },
        };
      }
      return job; // No changes for other jobs
    });
    dispatch(refreshJobs(uj));
    checkIfReadyForStep2(updatedSelectedKeywords, selectedLocations)
  };


  const removeSelectedKeyword = async (thisKeyword, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisJobId, jobsState) => {
    //add selected keyword into "selected keywords" array
    // console.log("thisJobId is " + thisJobId)
    // console.log("jobsState is " + JSON.stringify(jobsState))
    // console.log("thisKeyword is " + thisKeyword)

    // console.log("selectedKeywords is " + selectedKeywords)

    // Create new arrays for selected and unselected keywords
    const updatedSelectedKeywords = [...selectedKeywords];
    // // console.log("updatedSelectedKeywords is " + updatedSelectedKeywords)
    const updatedUnselectedKeywords = [...unselectedKeywords];

    // Take selected keyword out of the original array
    const indexToRemove = updatedSelectedKeywords.indexOf(thisKeyword); // Find the index of the value to remove
    if (indexToRemove !== -1) {
      updatedSelectedKeywords.splice(indexToRemove, 1); // Remove the item at the specified index
    }
    // console.log("updatedUnselectedKeywordsis " + updatedUnselectedKeywords)
    // // now put the selected keyword into the selected keywords array
    updatedUnselectedKeywords.push(thisKeyword)

    // // Find the job with the specified ID
    const uj = jobsState.map((job) => {
      if (job._id === thisJobId) {
        // Create a new object with updated properties
        return {
          ...job,
          SearchQuery: {
            topic: job.SearchQuery.topic,
            searchregion: job.SearchQuery.searchregion,
            keywords: updatedUnselectedKeywords,
            locations: unselectedLocations,
            selectedkeywords: updatedSelectedKeywords,
            selectedlocations: selectedLocations,
          },
        };
      }
      return job; // No changes for other jobs
    });
    dispatch(refreshJobs(uj));
    checkIfReadyForStep2(updatedSelectedKeywords, selectedLocations)
  };

  const handleNewSelectedLocation = async (thisLocation, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisJobId, jobsState) => {
    //add selected location into "selected locations" array

    // Create new arrays for selected and unselected keywords
    const updatedUnselectedLocations = [...unselectedLocations];
    const updatedSelectedLocations = [...selectedLocations];

    //  now take selected location out of the original array
    const indexToRemove = updatedUnselectedLocations.indexOf(thisLocation); // Find the index of the value to remove
    if (indexToRemove !== -1) {
      updatedUnselectedLocations.splice(indexToRemove, 1); // Remove the item at the specified index
    }
    // now put the selected location into the selected keywords array
    updatedSelectedLocations.push(thisLocation)

    // Find the job with the specified ID
    const uj = jobsState.map((job) => {
      if (job._id === thisJobId) {
        // Create a new object with updated properties
        return {
          ...job,
          SearchQuery: {
            topic: job.SearchQuery.topic,
            searchregion: job.SearchQuery.searchregion,
            keywords: unselectedKeywords,
            locations: updatedUnselectedLocations,
            selectedkeywords: selectedKeywords,
            selectedlocations: updatedSelectedLocations,
          },
        };
      }
      return job; // No changes for other jobs
    });
    dispatch(refreshJobs(uj));
    checkIfReadyForStep2(selectedKeywords, updatedSelectedLocations)
  };

  const removeSelectedLocation = async (thisLocation, unselectedKeywords, selectedKeywords, unselectedLocations, selectedLocations, thisJobId, jobsState) => {
    //add selected location into "selected locations" array

    // Create new arrays for selected and unselected keywords
    const updatedUnselectedLocations = [...unselectedLocations];
    const updatedSelectedLocations = [...selectedLocations];

    // //  now take selected location out of the original array
    const indexToRemove = updatedSelectedLocations.indexOf(thisLocation); // Find the index of the value to remove
    if (indexToRemove !== -1) {
      updatedSelectedLocations.splice(indexToRemove, 1); // Remove the item at the specified index
    }
    // // now put the selected location into the selected keywords array
    updatedUnselectedLocations.push(thisLocation)

    // // Find the job with the specified ID
    const uj = jobsState.map((job) => {
      if (job._id === thisJobId) {
        // Create a new object with updated properties
        return {
          ...job,
          SearchQuery: {
            topic: job.SearchQuery.topic,
            searchregion: job.SearchQuery.searchregion,
            keywords: unselectedKeywords,
            locations: updatedUnselectedLocations,
            selectedkeywords: selectedKeywords,
            selectedlocations: updatedSelectedLocations,
          },
        };
      }
      return job; // No changes for other jobs
    });
    dispatch(refreshJobs(uj));
    checkIfReadyForStep2(selectedKeywords, updatedSelectedLocations)
  };

  const updateJobInDb = async (jobId) => {
    // event.preventDefault()
    // console.log("UpdateJob API with " + jobId)
    // setIsSearching(true)

    const jobToEdit = allstate.jobs.map(async (job) => {
      if (job._id === jobId) {
        let url1 = process.env.REACT_APP_WFE_URL + "/database/update"
        await fetchData(url1, job, { "jwtToken": allstate.user.jwt })
        return job
      } else {
        // console.log("id doesnt match " + jobId)
      }
    })

    // const resp = await fetchData("https://wfe.gojummbo.comlocalhost:5321/database/update", jobToEdit[0], { "jwtToken": allstate.user.jwt })
    return jobToEdit;
  };

  const generatefictionalbio = async (jobId, jobsState, locations, keywords) => {
    console.log("generatefictionalbio with " + locations, keywords)
    // Join locations and keywords into comma-separated strings  
    const locationsString = locations.join(', ');
    const keywordsString = keywords.join(', ');

    let requestdata = {
      "jobid": jobId,
      "locations": locationsString,  // Use the comma-separated string  
      "keywords": keywordsString       // Use the comma-separated string  
    };
    let url = process.env.REACT_APP_WFE_URL + "/jobs/generatecolumns"
    console.log("requestdata" + JSON.stringify(requestdata))
    const generatedColumns = await fetchText(url, requestdata, { "jwtToken": allstate.user.jwt })


    // // Find the job with the specified ID, as we will add the columns into it now
    const uj = jobsState.map((job) => {
      if (job._id === jobId) {
        // Create a new object with updated properties
        return {
          ...job,
          Columns: generatedColumns
        };
      }
      return job; // No changes for other jobs
    });
    dispatch(refreshJobs(uj));
    return generatedColumns
  }

  // const proceedToStep2 = (id) => {
  //   console.log("move to step 2")
  //   navigate(`/builder/step2/${id}`);
  // };
  const proceedToStep2 = async (jobId, locations, keywords) => {
    setWaiting(true);
    try {
      await updateJobInDb(jobId); //save selected terms and search terms
      await generatefictionalbio(jobId, allstate.jobs, locations, keywords); //actually generate fictional bio, not columns
      await updateJobInDb(jobId); //update db again, now with columns in it
      setWaiting(false);
      navigate(`/builder/step3/${id}`);
      // return newColumns
    } catch (error) {
      setWaiting(false);
      console.error('Error updating job in the database:', error);
      // Handle the error appropriately
    }
  };
  return (

    <React.Fragment>
      <div className="component-card">
        {waiting && <Spinner />}
        {
          allstate.jobs.map((job, index) => (
            <React.Fragment>

              {job._id === id ? (
                <React.Fragment>
                  {job.SearchQuery.topic.length > 0 ? (
                    <React.Fragment>
                      <h3>{job.SearchQuery.topic}</h3>
                      <Row>
                        <div>What businesses are you searching for? Include some relevant <strong>search terms</strong> and a <strong>location</strong>.</div>
                        <div>
                          <textarea
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="eg: residential electricians in greater London UK"
                            className="custom-textarea"
                            rows={4}
                          />
                        </div>
                        <span>
                          {job.SearchQuery.topic.length > 0 ? (
                            <button className={"selected-button"} disabled={isSearching} onClick={() => handleButtonClick(inputValue)}>{isSearching ? 'Refining...' : 'Refine'}</button>
                          ) : <button disabled={isSearching} onClick={() => handleButtonClick(inputValue)} >{isSearching ? 'Searching...' : 'Start!'}</button>}
                        </span>

                        {job.SearchQuery.keywords.length > 0 ? (
                          <div>
                            <Row>
                              <Col xs={12} md={8}>
                                <br></br>
                                <div>
                                  <h5>Fine tune your list</h5>
                                  <h6>Select relevant terms:</h6>
                                  {job.SearchQuery.keywords.map((keyword, index) => (
                                    <React.Fragment>

                                      <span key={index}>
                                        {/* <Fade in={open}> */}
                                        <Badge onClick={() => handleNewSelectedKeyword(keyword, job.SearchQuery.keywords, job.SearchQuery.selectedkeywords, job.SearchQuery.locations, job.SearchQuery.selectedlocations, id, allstate.jobs)} className="unselected-badge">{keyword}</Badge>
                                        {/* </Fade> */}
                                      </span>

                                    </React.Fragment>
                                  ))}
                                </div>
                                <div>
                                  <br></br>
                                  <h6>Select relevant locations:</h6>
                                  {job.SearchQuery.locations.length > 0 ? (
                                    <React.Fragment>
                                      {
                                        job.SearchQuery.locations.map((location, index) => (
                                          <span key={index}><Badge onClick={() => handleNewSelectedLocation(location, job.SearchQuery.keywords, job.SearchQuery.selectedkeywords, job.SearchQuery.locations, job.SearchQuery.selectedlocations, id, allstate.jobs)} className="unselected-badge">{location}</Badge></span>
                                        ))
                                      }
                                    </React.Fragment>
                                  ) : (
                                    <span>Add a location or area to your search term above.</span>
                                  )
                                  }
                                </div>
                              </Col>


                              <Col xs={6} md={4}>
                                <br></br>
                                <h5>Selected Terms</h5>
                                {job.SearchQuery.selectedkeywords && job.SearchQuery.selectedkeywords.length > 0 ? (
                                  <React.Fragment>
                                    {
                                      job.SearchQuery.selectedkeywords.map((selectedkeyword, index) => (
                                        <Badge key={index} onClick={() => removeSelectedKeyword(selectedkeyword, job.SearchQuery.keywords, job.SearchQuery.selectedkeywords, job.SearchQuery.locations, job.SearchQuery.selectedlocations, id, allstate.jobs)} className="selected-badge">{selectedkeyword}  <ImCross size={"0.9em"} /></Badge>
                                      ))
                                    }
                                  </React.Fragment>
                                ) : (
                                  null
                                )
                                }
                                <br></br><br></br>
                                <h5>Selected Locations</h5>
                                {job.SearchQuery.selectedlocations && job.SearchQuery.selectedlocations.length > 0 ? (
                                  <React.Fragment>
                                    {
                                      job.SearchQuery.selectedlocations.map((selectedlocation, index) => (
                                        <Badge key={index} onClick={() => removeSelectedLocation(selectedlocation, job.SearchQuery.keywords, job.SearchQuery.selectedkeywords, job.SearchQuery.locations, job.SearchQuery.selectedlocations, id, allstate.jobs)} className="selected-badge">{selectedlocation} <ImCross size={"0.9em"} /></Badge>
                                      ))
                                    }
                                  </React.Fragment>
                                ) : (
                                  null
                                )
                                }
                                <span></span>
                                {readyForStep2 ? (
                                  <React.Fragment>
                                    <br></br>
                                    <br></br><br></br>
                                    <button className={"selected-button"} onClick={() => proceedToStep2(id, job.SearchQuery.selectedlocations, job.SearchQuery.selectedkeywords)}>Continue<FaAnglesRight /></button>
                                  </React.Fragment>
                                ) : <React.Fragment>
                                  <br></br>
                                  <br></br><br></br>
                                  <button className={"unselected-button"} disabled>Continue<FaAnglesRight /></button>
                                  <br></br>
                                  To continue, please select at least <strong>{selectedKeywordHurdle} term </strong> and <strong>{selectedLocationHurdle} locations</strong>.
                                </React.Fragment>
                                }
                              </Col>

                            </Row>
                          </div>
                        ) : (
                          <span></span>
                        )}



                      </Row>
                    </React.Fragment>



                  ) : null
                  }
                </React.Fragment>


              ) : null
              }
            </React.Fragment>
          ))
        }
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {

  return {
    allstate: state.myReducer
  };
};

export default connect(mapStateToProps)(Step1);
