import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LegalPage from './pages/legalPage';
import CreateAccountPage from './pages/createAccountPage';
import LoginPage from './pages/loginPage';
import DashboardPage from './pages/dashboardPage';
import SingleSitePage from './pages/singleSitePage';
import { useDispatch, connect } from 'react-redux';
import JobsPage from './pages/jobsPage'
import BillingPage from './pages/billingPage';
import SettingsPage from './pages/settingsPage';
// import PlaygroundStartPage from './pages/playgroundStartPage';
import BuilderPage from './pages/builderPage';
import Step3Page from './pages/step3Page';
import Step4Page from './pages/step4Page';
import NotFound from './components/NotFound';  // Import your 404 page component  
import ListUploadPage from './pages/listUploadPage';
import CadenceEditPage from './pages/cadenceEditPage';



const AppRoutes = (state) => {
  // Check if the user is authenticated (you can use your own logic here)
    const isAuthenticated = (state.state.user.isAuthenticated); // Example: Set this based on your authentication state

  return (
    <React.Fragment>
    {/* <p>hello {JSON.stringify(state.state.user.isAuthenticated)}</p> */}
    <Routes>
      <Route path="/legal" element={<LegalPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/createaccount" element={<CreateAccountPage />} />
      {/* Protected routes */}
      <Route path="/" element={isAuthenticated ? <DashboardPage /> : <Navigate to="/login" />} />
      <Route path="/dashboard" element={isAuthenticated ? <DashboardPage /> : <Navigate to="/login" />} />
      <Route path="/builder" element={isAuthenticated ? <BuilderPage /> : <Navigate to="/login" />} />
      <Route path="/singlesite" element={isAuthenticated ? <SingleSitePage /> : <Navigate to="/login" />} />
      <Route path="/listupload" element={isAuthenticated ? <ListUploadPage /> : <Navigate to="/login" />} />
      <Route path="/builder/:id" element={isAuthenticated ? <BuilderPage /> : <Navigate to="/login" />} />
      <Route path="/builder/step3/:id" element={isAuthenticated ? <Step3Page /> : <Navigate to="/login" />} />
      <Route path="/builder/step4/:id" element={isAuthenticated ? <Step4Page /> : <Navigate to="/login" />} />
      <Route path="/jobs" element={isAuthenticated ? <JobsPage /> : <Navigate to="/login" />} />
      <Route path="/billing" element={isAuthenticated ? <BillingPage /> : <Navigate to="/login" />} />
      <Route path="/settings" element={isAuthenticated ? <SettingsPage /> : <Navigate to="/login" />} />
      <Route path="/cadence/:id" element={isAuthenticated ? <CadenceEditPage /> : <Navigate to="/login" />} />
      <Route path="*" element={<NotFound />} />  {/* Catch-all route for 404 */}  
    </Routes>
    </React.Fragment>
  );
};

// export default AppRoutes;


const mapStateToProps = (state) => {
  return {
      state: state.myReducer
  };
};

export default connect(mapStateToProps)(AppRoutes);
