import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import DropdownMenu from '../components/DropdownMenu'
import logo from '../assets/images/Jummbo_PrimaryLogo_Forest.png'

const AdminHeader = (state) => {
  const { credits } = state.state.user;

  // Determine the appropriate class based on the credit balance  
  let badgeClass = '';
  if (credits > 100) {
    badgeClass = 'badge-high';
  } else if (credits > 30 && credits <= 100) {
    badgeClass = 'badge-medium';
  } else {
    badgeClass = 'badge-low';
  }

  return (
<React.Fragment>  
  <div className="top-menu-container d-flex justify-content-between align-items-center p-2 bg-light">  
    <img src={logo} alt="Logo" className="mobile-logo" />  
    <span className="top-menu d-flex justify-content-end align-items-center">  
      <span className="top-menu-items d-flex align-items-center">  
        <Badge className={`mr-2 ${badgeClass}`}>{credits} credits</Badge>  
        <DropdownMenu />  
      </span>  
    </span>  
  </div>  
</React.Fragment>  


  );
};



const mapStateToProps = (state) => {
  return {
    state: state.myReducer
  };
};

export default connect(mapStateToProps)(AdminHeader);
