import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { fetchData } from '../helpers/api'; // Import the fetchData function
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useDispatch, connect } from 'react-redux';
import { refreshJobs, refreshSingleSite, refreshSubscriptions, refreshListUpload, setJWT, refreshCadences } from '../redux/actions';
import herographic from '../assets/images/jummbobillboard.png';
import logo from '../assets/images/Jummbo_PrimaryLogo_Forest.png';
import { FaFileDownload } from 'react-icons/fa';


const LoginForm = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const dispatch = useDispatch();
  const [otpRequested, setRequestOtp] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [userEmail, setUserEmail] = useState(null)
  const [loginFailed, setLoginFailed] = useState(false)

  const handleOtpRequest = async (e) => {
    //send email to user to receive OTP
    e.preventDefault();
    const email = e.target.email.value;

    // Create form data
    const formData = new URLSearchParams();
    formData.append('email', email);
    setUserEmail(email)
    setRequestOtp(true)
    setIsLoggingIn(false)

    try {
      let url = process.env.REACT_APP_WFE_URL + "/email/sendotp"
      const response = await fetchData(url, { "toEmail": email })
      // console.log("user " + JSON.stringify(response))

      if (response === "OK") {
        // Handle login error (e.g., display an error message)
        console.error('Sending passcode to ' + email);
      } else {
        // Handle successful login (e.g., redirect to another page)
        // console.log('OTP requested with ' + email);
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  }

  const LoginFailedMsg = () => (
    //used to block the screen when async jobs are running
    < React.Fragment >
      {/* {setIsLoggingIn(false)} */}
      <p style={{ color: '#D46728' }}>Login failed, double check your passcode</p>
    </React.Fragment >
  );


  const handleSubmit = async (e) => {
    // console.log("User email is " + userEmail)
    e.preventDefault();
    // Handle form submission (e.g., validate input, make API requests, etc.)
    // Get form input values
    const passcode = e.target.twofa.value;
    // console.log("User email is " + userEmail)


    // Create form data
    const formData = new URLSearchParams();
    formData.append('email', userEmail);
    formData.append('password', passcode);
    setIsLoggingIn(true)

    try {
      // Send POST request
      let url = process.env.REACT_APP_WFE_URL + "/auth/login"
      const response = await fetchData(url, { "email": userEmail, "twofa": passcode })
      // console.log("user " + JSON.stringify(response))
      console.log("subscriptions " + JSON.stringify(response.subscriptions))

      if (response.status === "loginfailed") {
        // Handle login error (e.g., display an error message)
        console.error('Login failed');
      } else {

        // Handle successful login (e.g., redirect to another page)
        // console.log('Login successful');
        dispatch(setJWT(response.user))
        dispatch(refreshJobs(response.jobs))
        dispatch(refreshSingleSite(response.singlesite))
        dispatch(refreshListUpload(response.listupload))    
        dispatch(refreshCadences(response.cadences))
        // console.log("subscriptions from login are " + JSON.stringify(response.subscriptions))
        dispatch(refreshSubscriptions(response.subscriptions))
        navigate('/dashboard'); // Redirect to /dashboard

      }
    } catch (error) {
      setLoginFailed(true)
      setIsLoggingIn(false)
      console.error('Error during login:', error);
    }
  };



  return (
    <Container fluid className="vh-100">
      <Row className="h-100">

        <Col md={3} className="d-flex align-items-center">
          <div className="login-form">
            <img src={logo} alt="Jummbo logo" className="img-fluid smallerlogo" />
            <br></br><br></br>
            <h2>Login</h2>
            or <a href="/createaccount">Create account</a>.<br></br><br></br>
            <form onSubmit={handleOtpRequest}>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>

              {otpRequested ? (
                <React.Fragment><Button size="sm" type="submit" className="unselected-button">Re-send code</Button>
                  <br></br><br></br>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button size="sm" className="selected-button" type="submit">Send code</Button>
                </React.Fragment>
              )}
            </form>
            {otpRequested ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="twofa">Passcode</label>
                  <input id="twofa" name="twofa" type="text" required />
                </div>
                {isLoggingIn ? (
                  <React.Fragment>
                    <Button size="sm" className="unselected-button">Logging in...</Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment><Button size="sm" className="selected-button" type="submit">Login</Button>
                    {loginFailed && <LoginFailedMsg />}
                  </React.Fragment>
                )}
              </form>
            ) : null}

          </div>

        </Col>
        <Col md={9} className="d-flex justify-start align-items-center p-0">
          <img src={herographic} alt="Jummbo on the street" className="img-fluid full-height-img" />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    fullState: state.myReducer
  };
};


export default connect(mapStateToProps)(LoginForm);

