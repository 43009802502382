import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import { useDispatch, connect } from 'react-redux';
import BuilderProgress from '../components/BuilderProgress';
import MobileMenu from '../components/MobileMenu';
import Step3CompanyBrief from '../components/step3CompanyBrief';
import { FaAnglesRight } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { RiMailCheckFill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";


const Step3Page = (allstate) => {
  const { id } = useParams();
  const navigate = useNavigate(); // Get the navigate function
  const [prospectingEmailSelected, setProspectingEmailSelected] = useState(false);
  const [callScriptSelected, setCallScriptSelected] = useState(false);
  const [showProspectEmailDetails, setShowProspectEmailDetails] = useState(false);
  const [showCallScriptDetails, setShowCallScriptDetails] = useState(false);
  const [showPersonalisationForm, setShowPersonalisationForm] = useState(false);
  const [showProspectEmailPreview, setShowProspectEmailPreview] = useState(true);
  const [showCallScriptPreview, setShowCallScriptPreview] = useState(true);
  const [sampleProspectEmailExists, setSampleProspectEmailExists] = useState(false);
  const [sampleCallScriptExists, setSampleCallScriptExists] = useState(false);

  const navToPage4 = async (jobId) => {
    navigate(`/builder/step4/${id}`);
  }


  const toggleShowProspectEmailPreview = () => {
    if (showProspectEmailPreview) {
      setShowProspectEmailPreview(false)
      // console.log("setShowProspectEmailPreview set to false")
    } else {
      setShowProspectEmailPreview(true)
      // console.log("setShowProspectEmailPreview set to true")
    }
  };


  const toggleShowCallScriptPreview = () => {
    if (showCallScriptPreview) {
      setShowCallScriptPreview(false)
      // console.log("setShowCallScriptPreview set to false")
    } else {
      setShowCallScriptPreview(true)
      // console.log("setShowCallScriptPreview set to true")
    }
  };

  const toggleShowPersonalisationForm = () => {
    if (showPersonalisationForm) {
      setShowPersonalisationForm(false)
      // console.log("showPersonalisationForm set to false")
    } else {
      setShowPersonalisationForm(true)
      // console.log("showPersonalisationForm set to true")
    }
  };


  const toggleSelectProspectingEmail = () => {
    if (prospectingEmailSelected) {
      setProspectingEmailSelected(false)
      // console.log("toggleSelectProspectingEmail set to false")
    } else {
      setProspectingEmailSelected(true)
      // console.log("toggleSelectProspectingEmail set to true")
    }
  };

  const toggleSelectCallScript = () => {
    if (callScriptSelected) {
      setCallScriptSelected(false)
      // console.log("toggleSelectCallScript set to false")
    } else {
      setCallScriptSelected(true)
      // console.log("toggleSelectCallScript set to true")
    }
  };

  const toggleShowProspectEmailDetails = (index) => {
    if (showProspectEmailDetails) {
      setShowProspectEmailDetails(false)
    } else {
      setShowProspectEmailDetails(true)
    }
  };

  const toggleShowCallScriptDetails = (index) => {
    if (showCallScriptDetails) {
      setShowCallScriptDetails(false)
    } else {
      setShowCallScriptDetails(true)
    }
  };


  return (
    <React.Fragment>
      <div className="container">
        <div class="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <Row>
            <Col className="adminBackground">
              <BuilderProgress id={id} stage='3' />

              <h2>Personalisation & Your Organisation</h2>
              <Row>
                <Col>
                  <br></br><br></br>To generate the most personalised emails and call scripts provide us some information about your organisation.
                  <br></br><br></br>This will be used by our AI to generate on-message and compelling text to speed up your sales pipeline growth.
                  <br></br><br></br>It's like having your very own private version of Chat GPT that drafts a message for every entry in your list!
                </Col>
                <Col>
                  <Card className="card-dashboard">
                    <Card.Body>
                      <Card.Title><span className='generate-text-selected'><FaCheckCircle /></span> Your Business Info</Card.Title>
                      <Card.Text>
                        You've already told us about your business, so each call script and prospecting email will be highly targeted.
                        <br></br><br></br>
                        <Link to={`/settings`} className='hyperlink'>Edit</Link>
                        <br></br>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {
                allstate.allstate.jobs.map((job, index) => (
                  <React.Fragment>


                    {
                      job._id === id ? (
                        <React.Fragment>
                          <div className="component-card">
                              <React.Fragment>
                                {/* <span onClick={() => toggleShowPersonalisationForm()}><br></br><a href="#">Hide form</a><br></br><br></br></span> */}
                                <Step3CompanyBrief id={job._id} />
                              </React.Fragment>                          
                          </div>

                          <Row>
                            <Col md={6}>
                            <div className="component-card">
                                <span ><h3><span className='generate-text-selected'><RiMailCheckFill /></span>Prospecting Email</h3></span>
                                {showProspectEmailPreview == true ? (
                                  <React.Fragment><span className="hyperlink" onClick={() => toggleShowProspectEmailPreview()}>Hide Preview</span> | </React.Fragment>
                                ) : <React.Fragment><span className="hyperlink" onClick={() => toggleShowProspectEmailPreview()}>View Preview</span> | </React.Fragment>
                                }
                                {showProspectEmailDetails == true ? (
                                  <React.Fragment>
                                    <span className="hyperlink" onClick={() => toggleShowProspectEmailDetails()}>Hide details</span><br></br><br></br>
                                    <span className='custom-form-label'>Contains:</span>
                                    <ul>
                                      <li>Subject Line - attracts attention quickly with concise wording, increasing email open rates.</li>
                                      <li>Warm introduction - establishes rapport immediately, fostering a positive initial impression.</li>
                                      <li>Clear value proposition - articulates benefits succinctly, showcasing your relevance and expertise upfront.</li>
                                      <li>Engaging question - sparks a conversation about your prospect's needs, guiding the direction of the dialogue.</li>
                                      <li>Intriguing teaser - builds curiosity about your product/services, prompting further interest and inquiry.</li>
                                      <li>Call to action - encourages response and interaction, facilitating the next steps in the sales process.</li>
                                    </ul>
                                    <span className='custom-form-label'>Other useful info:</span>
                                    <ul>
                                      <li>Every email is personalised and focuses on the overlap of your product/services and your prospect's business to maximise lead conversion.</li>
                                      <li>Being unique and completely AI generated, these emails help you avoid spam filters.</li>
                                      <li>Ready for copy/paste into your preferred email client, or importing into your sales prospecting tool.</li>
                                      <li>Follows best practice guidance from sales experts such as Sandler and the SPIN selling method when cold prospecting.</li>
                                    </ul>
                                  </React.Fragment>
                                ) : <React.Fragment><span className="hyperlink" onClick={() => toggleShowProspectEmailDetails()}>Learn more</span></React.Fragment>
                                }
                                <br></br>
                                {showProspectEmailPreview == true ? (
                                  <React.Fragment>
                                    {
                                      job.textGen.prospectEmailPreview ? (
                                        <React.Fragment><span>${job.textGen.prospectEmailPreview}</span></React.Fragment>
                                      ) : null
                                    }
                                  </React.Fragment>
                                ) : null}
                                {showProspectEmailPreview == true ? (
                                  <React.Fragment>
                                    <br></br>
                                    <span div className="preview-generated-content">{job.textGen.previewEmail}</span>                              </React.Fragment>
                                ) : null
                                }
                              </div>

                            </Col>
                            <Col md={6}>
                            <div className="component-card">
                                <span ><h3><span className='generate-text-selected'><FaPhone /></span>Call Script</h3></span>
                                {showCallScriptPreview == true ? (
                                  <React.Fragment><span onClick={() => toggleShowCallScriptPreview()} className="hyperlink">Hide Preview</span> | </React.Fragment>
                                ) : <React.Fragment><span onClick={() => toggleShowCallScriptPreview()} className="hyperlink">View Preview</span> | </React.Fragment>
                                }
                                {showCallScriptDetails == true ? (
                                  <React.Fragment>
                                    <span className="hyperlink" onClick={() => toggleShowCallScriptDetails()}>Hide details</span><br></br><br></br>
                                    <span className='custom-form-label'>Contains:</span>
                                    <ul>
                                      <li>Introduction - greet the prospect, introduce yourself and your company, and establish a connection if possible.</li>
                                      <li>Transition - smoothly segue into the purpose of the call.</li>
                                      <li>Clear value proposition - states the benefits and solutions your product or service offers for the prospect's needs.</li>
                                      <li>Qualifying question - demonstrates relevance and expertise with open-ended question to understand your prospect's challenges and gather relevant information.</li>
                                      <li>Next steps - proposes a clear next step based on the prospect's interest, such as scheduling a demo or sending more information.</li>
                                      <li>Close & thank you - thanks the prospect for their time and leaves the door open for future communication.</li>
                                    </ul>
                                    <span className='custom-form-label'>Other useful info:</span>
                                    <ul>
                                      <li>Each script is written in a conversational style, using contractions like 'we'll' instead of 'we will,' to enhance clarity and professionalism, making it more user-friendly for your sales agents.</li>
                                      <li>Open-ended questions are posed to encourage dialogue.</li>
                                      <li>Every conversation is personalised and focuses on the overlap of your product/services and your prospect's business to maximise sales pipeline creation.</li>
                                      <li>Ready for copy/paste into your preferred call centre software, or simply follow the script and start calling.</li>
                                    </ul>
                                  </React.Fragment>
                                ) : <React.Fragment><span className="hyperlink" onClick={() => toggleShowCallScriptDetails()}>Learn more</span></React.Fragment>
                                }
                                {showCallScriptPreview == true ? (
                                  <React.Fragment>
                                    <br></br><br></br>
                                    <span div className="preview-generated-content">{job.textGen.previewCallScript}</span>                              </React.Fragment>
                                ) : null
                                }

                              </div>
                            </Col>
                          </Row>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => navToPage4(job._id)}>
                              Continue <FaAnglesRight />
                            </Button>
                          </div>
                        </React.Fragment>

                      ) : null}


                  </React.Fragment>
                ))
              }

            </Col>
          </Row >
        </div>
      </div>
    </React.Fragment >
  );
};



const mapStateToProps = (state) => {
  return {
    allstate: state.myReducer
  };
};

export default connect(mapStateToProps)(Step3Page);