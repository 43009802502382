import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import AdminHeader from '../components/AdminHeader';
import MobileMenu from '../components/MobileMenu';
import { useDispatch, connect } from 'react-redux';
import { updateUserBio, updateUserLanguage } from '../redux/actions';
import { fetchData } from '../helpers/api';

const SettingsPage = (state) => {
  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const userBio = state.state.user.bio || 'Enter your bio (up to 500 words)';
  const userLanguage = state.state.user.language || 'Enter language (up to 100 characters)';
  const languages = [
    // 'Arabic - Egypt',
    // 'Arabic - Saudi Arabia',
    // 'Arabic - United Arab Emirates',
    // 'Chinese - Mainland China (Simplified)',
    // 'Chinese - Taiwan (Traditional)',
    // 'Chinese - Hong Kong (Traditional)',
    // 'Dutch - Belgium',
    // 'Dutch - Netherlands',
    'English - Australian',
    'English - Canadian',
    // 'English - India',
    'English - New Zealand',
    'English - South African',
    'English - United Kingdom',
    'English - United States',
    // 'French - Belgium',
    // 'French - Canada',
    // 'French - France',
    // 'French - Switzerland',
    // 'German - Austria',
    // 'German - Germany',
    // 'German - Switzerland',
    // 'Italian - Italy',
    // 'Italian - Switzerland',
    // 'Portuguese - Brazil',
    // 'Portuguese - Portugal',
    // 'Spanish - Argentina',
    // 'Spanish - Colombia',
    // 'Spanish - Mexico',
    // 'Spanish - Spain',
    // 'Spanish - United States',
    // 'Swedish - Finland',
    // 'Swedish - Sweden'
  ]

  async function updateBio(event) {
    // setWaiting(true); 
    event.preventDefault(); // Prevent the default form submission behavior
    setIsSaving(true)
    // Check if bioValue field is not empty
    if (event.target.elements.bio.value.trim()) {
      var bioValue = event.target.elements.bio.value;
    } else {
      var bioValue = userBio;
    }

    // Check if languageValue field is not empty
    if (event.target.elements.language.value.trim()) {
      var languageValue = event.target.elements.language.value;
    } else {
      var languageValue = userLanguage;
    }


    await dispatch(updateUserBio(bioValue));
    await dispatch(updateUserLanguage(languageValue));
    let url = process.env.REACT_APP_WFE_URL + "/database/updateuser"
    await fetchData(url, { "_id": state.state.user.id, "bio": bioValue, "language": languageValue }, { "jwtToken": state.state.user.jwt })
    setTimeout(() => {
      setIsSaving(false);
    }, 3000);
  }

  return (
    <React.Fragment>
      <div className="container">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Row>
            <AdminHeader />
          </Row>
          <div class="col-md-3 d-block d-md-none">
          <MobileMenu />
        </div>
          <Row>
            <Col className="adminBackground">
            
              <div>
                <h2>Personalisation Settings</h2>
              </div>
              <div className="component-card">
              <Row>
                <Form onSubmit={updateBio}>
                  {/* Language */}
                  <Form.Group controlId="language">
                    <Form.Label className="custom-form-label">Search Region & Language</Form.Label>
                    <div className="form-tip">This setting ensures:
                      <ul><li>web searches are run from the region you nominate, providing improved results.</li>
                        <li>spelling in generated text is localised to the region you are targeting. eg "Organization" vs "Organisation"</li></ul></div>
                    <Form.Control as="select" defaultValue={userLanguage}>
                      {languages.map((language, index) => (
                        <option key={index} value={language}>
                          {language}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <br></br>
                  {/* Bio */}
                  <Form.Group controlId="bio">
                    <Form.Label className="custom-form-label">Your Company Bio</Form.Label>
                    <div className="form-tip">Used to ensure every prospect email and call script is aligned to what you do.</div>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      defaultValue={userBio}
                    />
                  </Form.Group>
                  <div className="form-tip">Include:</div>
                  <div className="form-tip">Your business name, your product/service, why you are the best at what you do, the industries/business types you service, a case study/success story, where you are based, basically anything you would want to tell a prospective customer.</div>
                  <div className="form-tip">Formatting doesn't matter - you can copy/paste straight from your website "about us" page if you wish.</div>
                  <br></br>


                  {isSaving ? (
                    <button className="unselected-button" disabled>Saving...</button>
                  ) : (
                    <button className="selected-button" variant="primary" type="submit">
                      Set Bio
                    </button>
                  )}

                </Form >
              </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment >
  );
};


const mapStateToProps = (state) => {
  return {
    state: state.myReducer
  };
};


export default connect(mapStateToProps)(SettingsPage);

