// Sidebar.js
import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, Link, Navigate } from 'react-router-dom';
import { IoIosHome, IoIosColorWand, IoIosCard } from "react-icons/io";
import { RiMenuSearchLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import { IoMdSettings } from "react-icons/io";
import { connect } from 'react-redux';
import { TbWorldWww } from "react-icons/tb";
import { IoChatbubbleOutline } from "react-icons/io5";
import logo from '../assets/images/Jummbo_PrimaryLogo_Forest.png'

const SidebarMenu = (user) => {

  const email = user.user.email
  const username = email.split("@")[0];

  // Get the current route path
  const currentRoute = window.location.pathname;

  // Define a function to check if the current route starts with "/builder"
  const isBuilderRoute = currentRoute.startsWith('/builder');


  return (
    <div className="sidebar">
      <Nav className="sidebar" defaultActiveKey="/dashboard">
      <img src={logo} alt="Logo" />
        <NavLink to="/dashboard" activeClassName="active" className="sidebar-item">
          <IoIosHome className='icon' />
          Dashboard
        </NavLink>
        <NavLink to="/singlesite" activeClassName="active" className="sidebar-item">
          <TbWorldWww className='icon' />
          Prospect a URL
        </NavLink>
        <NavLink to="/listupload" activeClassName="active" className="sidebar-item">
          <FiUploadCloud className='icon' />
          Prospect a List
        </NavLink>
        <NavLink to="/jobs" activeClassName="active" className="sidebar-item">
          <RiMenuSearchLine className='icon' />
          Build a Prospect List
        </NavLink>
        <div className="sidebar-section">ADMIN</div>
        <NavLink to="/settings" activeClassName="active" className="sidebar-item">
          <IoMdSettings className='icon' />
          Settings
        </NavLink>
        <NavLink to="/billing" activeClassName="active" className="sidebar-item">
          <IoIosCard className='icon' />
          Billing
        </NavLink>
        <NavLink to="mailto:support@jummbo.ai" activeClassName="active" className="sidebar-item">
          <IoChatbubbleOutline className='icon' />
          Support
        </NavLink>
      </Nav>


        
        {/* {isBuilderRoute ? (
          <NavLink>
            <IoIosColorWand className='icon-xl' />
            <br />Builder
          </NavLink>
        ) : (
          null
        )} */}



    </div >
  );
};



const mapStateToProps = (state) => {
  return {
    user: state.myReducer.user
  };
};

export default connect(mapStateToProps)(SidebarMenu);

// export default SidebarMenu;